import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { 
  loadUserDetailsService, 
  loginUserService, 
  registerUserService, 
  resetPasswordConfirmService, 
  resetPasswordService, 
  activateUserAccountService, 
  editUserDetailsService} from '../services/userAxiosServices';
import { RootState } from './store';
import { PasswordConfirmType, User, activateUserAccountServiceType, UserActivityBackendType, EditUserServiceType } from '../common/user-model';
import { getMyActivityService } from '../services/userActivityServices';
import { UserActivityType } from '../common/user-activity-model';


interface UserState {
  isAuthenticated: boolean;
  firstName: string | undefined;
  lastName: string | undefined;
  avatar: string | undefined;
  email: string | undefined;
  id: string | undefined;
  region: string | undefined;
  municipality: string | undefined;
  town: string | undefined;
  school: string | undefined;
  dateOfBirth: string | undefined;
  gender: string | undefined;
  ranking: number | undefined;
  receiveInvitationsForCategories: boolean;
  receiveInvitationsForContest: boolean;
  receiveInvitationsForFriends: boolean;
  receivePromoEmails: boolean;
  endTrialDate: string | undefined;
  endSubscriptionDate: string | undefined;
  aiUsage: number;
  aiImageUsage: number;
  subscriptionType: string | undefined;
  endAnnualSubscriptionDate: string | undefined;
  userActivity: UserActivityType[];
  registrationSuccess: boolean;
  changePasswordSuccess: boolean
  userCardsActivityLoading: boolean;
  loginLoading: boolean;
  registrationLoading: boolean;
  activateUserAccountLoading: boolean;
  loadingLogout: boolean;
  changePasswordLoading: boolean;
  changePasswordConfirmLoading: boolean;
  loginError: string | undefined;
  registrationError: string | undefined;
  userCardsActivityError: string | undefined
  changePasswordError: string | undefined;
  changePasswordConfirmError: string | undefined;
  activateUserAccountError: string | undefined;
  loadUserDetailsError: string | undefined;
  loadUserDetailsLoading: boolean;
  loadingEditUserDetails: boolean;
  errorEditUserDetails: string | undefined;
}

const initialState: UserState = {
  isAuthenticated: false,
  firstName: undefined,
  lastName: undefined,
  avatar: undefined,
  email: undefined,
  id: undefined,
  region: undefined,
  municipality: undefined,
  town: undefined,
  school: undefined,
  dateOfBirth: undefined,
  gender: undefined,
  ranking: undefined,
  receiveInvitationsForCategories: false,
  receiveInvitationsForContest: false,
  receiveInvitationsForFriends: false,
  receivePromoEmails: false,
  endTrialDate: undefined,
  endSubscriptionDate: undefined,
  aiUsage: 0,
  aiImageUsage: 0,
  subscriptionType: undefined,
  endAnnualSubscriptionDate: undefined,
  userActivity: [],
  registrationSuccess: false,
  changePasswordSuccess: false,
  userCardsActivityLoading: false,
  loginLoading: false,
  registrationLoading: false,
  activateUserAccountLoading: false,
  loadingLogout: false,
  changePasswordLoading: false,
  changePasswordConfirmLoading: false,
  loginError: undefined,
  registrationError: undefined,
  userCardsActivityError: undefined,
  changePasswordError: undefined,
  changePasswordConfirmError: undefined,
  activateUserAccountError: undefined,
  loadUserDetailsLoading: false,
  loadUserDetailsError: undefined,
  loadingEditUserDetails: false,
  errorEditUserDetails: undefined,
}


export const registerUser = createAsyncThunk('user/registerUser', async ({
  firstName,
  lastName,
  email,
  password,
  rePassword
}: User) => {
  let response
  try {
    response = await registerUserService({
      firstName,
      lastName,
      email,
      password,
      rePassword
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if(error.response && error.response.data.password) {
      throw error.response.data.password.toString()
    } 
    else if(error.response && error.response.data.email) {
      throw error.response.data.email.toString()
    } 
    else {
      throw error
    }
  }
  return response?.data
})

export const activateUserAccount = createAsyncThunk('user/activateUserAccount', async ({ uid, token }: activateUserAccountServiceType) => {
  try {
    activateUserAccountService({ uid, token })
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const loginUser = createAsyncThunk('user/loginUser', async ({
  email,
  password
}: Pick<User, 'email' | 'password'>) => {
  let user;
  try {
    user = await loginUserService({
      email,
      password,
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if(error.response && error.response.data.detail) {
      throw error.response.data.detail
    } else {
      throw error
    }
  }
  return user.data;
})

export const loadUserDetail = createAsyncThunk('user/loadUserDetail', async () => {
  let response
  try {
    response = await loadUserDetailsService()
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const editUserDetail = createAsyncThunk('user/editUserDetail', async ({
  id,
  avatar,
  firstName,
  lastName,
  email,
  region,
  municipality,
  town,
  school,
  gender,
  dateOfBirth,
  receiveInvitationsForCategories,
  receiveInvitationsForContest,
  receiveInvitationsForFriends,
  receivePromoEmails
}: EditUserServiceType) => {
  let response
  try {
    response = await editUserDetailsService({
      id,
      avatar,
      firstName,
      lastName,
      email,
      region,
      municipality,
      town,
      school,
      gender,
      dateOfBirth,
      receiveInvitationsForCategories,
      receiveInvitationsForContest,
      receiveInvitationsForFriends,
      receivePromoEmails
    })
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const resetPassword = createAsyncThunk('user/resetPassword', async (email: string) => {
  try {
    await resetPasswordService(email)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if(error.response && error.response.data.detail) {
      throw error.response.data.detail
    } else {
      throw error
    }
  }
})

export const resetPasswordConfirm = createAsyncThunk('user/resetPasswordConfirm', async (
  { uid, token, newPassword, reNewPassword }: PasswordConfirmType
) => {
  try {
    await resetPasswordConfirmService({ uid, token, newPassword, reNewPassword })
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any 
  catch (error: any) {
    console.error(error)
    if(error.response && error.response.data.new_password) {
      throw error.response.data.new_password.toString()
    }
    else {
      throw error
    }
  }
})

export const fetchUserActivity = createAsyncThunk('user/userActivity', async () => {
  let response
  try {
    response = await getMyActivityService()
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    cleanLoginError: (state) => {
      state.loginError = undefined
    },
    cleanPasswordResetError: (state) => {
      state.changePasswordError = undefined
    },
    cleanRegisterError: (state) => {
      state.registrationError = undefined
    },
    cleanUserActivity: (state) => {
      state.userActivity = []
    },
    logout: (state) => {
      state.email = undefined
      state.firstName = undefined
      state.lastName = undefined
      state.avatar = undefined
      state.loadUserDetailsLoading = false
      state.loadUserDetailsError = undefined
      state.id = undefined
      localStorage.removeItem('access')
      localStorage.removeItem("refresh")
    },
    cleanGetUserError: (state) => {
      state.loadUserDetailsError = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state: UserState) => {
        state.registrationError  = undefined
        state.registrationLoading = true
        state.registrationSuccess = false
      })
      .addCase(registerUser.fulfilled, (state: UserState) => {
        state.registrationLoading = false;
        state.registrationSuccess = true
      })
      .addCase(registerUser.rejected, (state: UserState, action) => {
        state.registrationLoading = false;
        state.registrationSuccess = false
        console.log(action.error)
        state.registrationError = action.error.message
      });
    builder
      .addCase(activateUserAccount.pending, (state: UserState) => {
        state.activateUserAccountError = undefined
        state.activateUserAccountLoading = true;
      })
      .addCase(activateUserAccount.fulfilled, (state: UserState) => {
        state.activateUserAccountLoading = false;
      })
      .addCase(activateUserAccount.rejected, (state: UserState, action) => {
        state.activateUserAccountLoading = false
        state.activateUserAccountError = action.error.message
      })
    builder
      .addCase(loginUser.pending, (state: UserState) => {
        state.loginError = undefined;
        state.loginLoading = true
      })
      .addCase(loginUser.fulfilled, (state: UserState, action) => {
        localStorage.setItem('access', action.payload.access);
        localStorage.setItem('refresh', action.payload.refresh);
        state.loginLoading = false;
      })
      .addCase(loginUser.rejected, (state: UserState, action) => {
        state.loginLoading = false;
        console.log(action.error)
        state.loginError = action.error.message;
      });
    builder
      .addCase(loadUserDetail.pending, (state: UserState) => {
        state.loadUserDetailsError = undefined
        state.loadUserDetailsLoading = true
        state.isAuthenticated = false
      })
      .addCase(loadUserDetail.fulfilled, (state: UserState, action) => {
        state.loadUserDetailsLoading = false
        state.isAuthenticated = true;
        state.firstName = action.payload.first_name
        state.lastName = action.payload.last_name
        state.avatar = action.payload.avatar
        state.id = action.payload.id,
        state.region = action.payload.region,
        state.municipality = action.payload.municipality,
        state.town = action.payload.town,
        state.school = action.payload.school,
        state.gender = action.payload.gender,
        state.dateOfBirth = action.payload.date_of_birth,
        state.ranking = action.payload.ranking,
        state.receiveInvitationsForCategories = action.payload.receive_invitations_for_categories,
        state.receiveInvitationsForContest = action.payload.receive_invitations_for_contest,
        state.receiveInvitationsForFriends = action.payload.receive_invitations_for_friends,
        state.receivePromoEmails = action.payload.receive_promo_emails,
        state.email = action.payload.email
        state.aiUsage = action.payload.ai_usage
        state.aiImageUsage = action.payload.ai_image_usage
        state.subscriptionType = action.payload.subscription_type
        state.endTrialDate = action.payload.trial_end_date
        state.endSubscriptionDate = action.payload.subscription_end_date
        state.endAnnualSubscriptionDate = action.payload.annual_subscription_end_date
      })
      .addCase(loadUserDetail.rejected, (state: UserState, action) => {
        state.loadUserDetailsLoading = false
        state.isAuthenticated = false
        if(action.error.message?.includes('Token not valid')) {
          state.isAuthenticated = true
          console.log(action.error.message)
        } else {
          state.loadUserDetailsError = action.error.message
        }
      });
    builder
      .addCase(editUserDetail.pending, (state: UserState) => {
        state.errorEditUserDetails = undefined
        state.loadingEditUserDetails = true
      })
      .addCase(editUserDetail.fulfilled, (state: UserState) => {
        state.loadingEditUserDetails = false        
      })
      .addCase(editUserDetail.rejected, (state: UserState, action) => {
        state.loadingEditUserDetails = false
        if(action.error.message?.includes('Token not valid')) {
          state.isAuthenticated = true
          console.log(action.error.message)
        } else {
          state.errorEditUserDetails = action.error.message
        }
      });
    builder
      .addCase(resetPassword.pending, (state: UserState) => {
        state.changePasswordError = undefined
        state.changePasswordLoading = true
      })
      .addCase(resetPassword.fulfilled, (state: UserState) => {
        state.changePasswordLoading = false
      })
      .addCase(resetPassword.rejected, (state: UserState, action) => {
        state.changePasswordLoading = false
        state.changePasswordError = action.error.message
      })
    builder
      .addCase(resetPasswordConfirm.pending, (state: UserState) => {
        state.changePasswordConfirmError = undefined
        state.changePasswordConfirmLoading = true
        state.changePasswordSuccess = false
      })
      .addCase(resetPasswordConfirm.fulfilled, (state: UserState) => {
        state.changePasswordConfirmLoading = false
        state.changePasswordSuccess = true
      })
      .addCase(resetPasswordConfirm.rejected, (state: UserState, action) => {
        state.changePasswordConfirmLoading = false
        state.changePasswordSuccess = false
        state.changePasswordConfirmError = action.error.message
      })
    builder
      .addCase(fetchUserActivity.pending, (state: UserState) => {
        state.userCardsActivityLoading = true
      })
      .addCase(fetchUserActivity.fulfilled, (state: UserState, action) => {
        state.userCardsActivityLoading = false
        const userActivityList: UserActivityType[] = []
        action.payload.forEach((activity: UserActivityBackendType) => {
          const userActivityObject: UserActivityType = {
            lastUpdate: activity.create_at,
            cardRequests: activity.card_requests,
            learnedCardsVerbal: activity.learned_cards_verbal,
            learnedCardsTyping: activity.learned_cards_typing,
            completedCategoriesVerbal: activity.completed_categories_verbal,
            completedCategoriesTyping: activity.completed_categories_typing,
            dailyLimit: activity.daily_limit
          }
          userActivityList.push(userActivityObject)
        })

        state.userActivity = userActivityList
      })
      .addCase(fetchUserActivity.rejected, (state: UserState, action) => {
        state.userCardsActivityLoading = false
        state.userCardsActivityError = action.error.message
      })
  },
})

export const actions = userSlice.actions;

export const usersSelector = (state: RootState) => state[userSlice.name];

export const loginSelector = createSelector(usersSelector, (x) => ({
  loginLoading: x.loginLoading,
  loginError: x.loginError,
  loadUserDetailsLoading: x.loadUserDetailsLoading,
}))
export const registerSelector = createSelector(usersSelector, (x) => ({
  registrationLoading: x.registrationLoading,
  registrationError: x.registrationError,
  registrationSuccess: x.registrationSuccess,
}))
export const isAuthenticatedSelector = createSelector(usersSelector, (x) => ({
  isAuthenticated: x.isAuthenticated,
  loadUserDetailsError: x.loadUserDetailsError,
  firstName: x.firstName,
  lastName: x.lastName,
  avatar: x.avatar,
  email: x.email,
  aiUsage: x.aiUsage,
  aiImageUsage: x.aiImageUsage,
  subscriptionType: x.subscriptionType,
  id: x.id,
  region: x.region,
  municipality: x.municipality,
  town: x.town,
  school: x.school,
  receiveInvitationsForCategories: x.receiveInvitationsForCategories,
  receiveInvitationsForContest: x.receiveInvitationsForContest,
  receiveInvitationsForFriends: x.receiveInvitationsForFriends,
  receivePromoEmails: x.receivePromoEmails,
  loadUserDetailsLoading: x.loadUserDetailsLoading,
  endTrialDate: x.endTrialDate,
  endSubscriptionDate: x.endSubscriptionDate,
  endAnnualSubscriptionDate: x.endAnnualSubscriptionDate
}))

export const changePasswordSelector = createSelector(usersSelector, (x) => ({
  changePasswordLoading: x.changePasswordLoading,
  changePasswordError: x.changePasswordError
}))

export const changePasswordConfirmSelector = createSelector(usersSelector, (x) => ({
  changePasswordConfirmLoading: x.changePasswordConfirmLoading,
  changePasswordConfirmError: x.changePasswordConfirmError,
  changePasswordSuccess: x.changePasswordSuccess
}))

export const userActivitySelector = createSelector(usersSelector, (x) => ({
  userCardsActivityLoading: x.userCardsActivityLoading,
  userActivity: x.userActivity,
  userCardsActivityError: x.userCardsActivityError
}))

export const editUserDetailsSelector = createSelector(usersSelector, (x) => ({
  id: x.id,
  firstName: x.firstName,
  lastName: x.lastName,
  avatar: x.avatar,
  email: x.email,
  aiUsage: x.aiUsage,
  aiImageUsage: x.aiImageUsage,
  subscriptionType: x.subscriptionType,
  region: x.region,
  municipality: x.municipality,
  town: x.town,
  school: x.school,
  gender: x.gender,
  dateOfBirth: x.dateOfBirth,
  receiveInvitationsForCategories: x.receiveInvitationsForCategories,
  receiveInvitationsForContest: x.receiveInvitationsForContest,
  receiveInvitationsForFriends: x.receiveInvitationsForFriends,
  receivePromoEmails: x.receivePromoEmails,
  loadingEditUserDetails: x.loadingEditUserDetails,
  errorEditUserDetails: x.errorEditUserDetails,
}))



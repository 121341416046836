import { BASE_URL } from "../common/constants";
import axios from "axios";
import { withTokenCheck } from "./userAxiosServices"

///CATEGORIES SERVICES

const getMyActivity = async () => {
  const response = axios.get(BASE_URL + `card/card-requests/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getMyActivityService = withTokenCheck(getMyActivity)

const getFriendActivity = async (friendId: string) => {
  const response = axios.get(BASE_URL + `card/card-requests/${friendId}`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getFriendActivityService = withTokenCheck(getFriendActivity)


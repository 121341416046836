import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';


function NotFound() {
  const {t} = useTranslation()
  const navigate = useNavigate()

  // const handleNotFoundRequest = useCallback(async () =>{
  //   await fetch("https://slflearner.com/services")
  // }, [])

  // useEffect(() => {

  // })
  const handleNavigateHomePage = useCallback(() => {
    navigate('/')
  }, [navigate])
  return (
    <div className='not-found-page'>
      <h1 className='not-found-header'>{t('notFound.header')}</h1>
      <p className='not-found-404'>error: 404</p>
      <Button color="primary" onClick={handleNavigateHomePage}>{t('notFound.homeBtn')}</Button>
    </div>
  )
}

export default NotFound
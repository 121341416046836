import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FriendUserModel, SearchFriendType, SendFriendsRequest } from "../common/friends-model";
import { SearchUser, UserActivityBackendType } from "../common/user-model";
import {
  sendFriendshipRequestService,
  acceptFriendshipRequestService,
  rejectFriendshipRequestService,
  cancelFriendshipRequestService,
  getFriendshipsService,
  removeFriendService,
  blockUserService,
  unblockUserService,
  getBlockedUsersService,
  searchForFriendsService,
  getFriendshipsRequestsService,
  friendDetailsService,
  friendFriendsService
} from "../services/friendsAxiosServices";
import { RootState } from "./store";
import { getFriendActivityService } from "../services/userActivityServices";
import { UserActivityType } from "../common/user-activity-model";


interface UserFriendsType {
  searchedUsers: FriendUserModel[];
  friendsList: FriendUserModel[];
  friendFriendsList: FriendUserModel[];
  friendsRequestsList: FriendUserModel[];
  blockedUsersList: FriendUserModel[];
  friendDetails: FriendUserModel;
  loadingFriendDetails: boolean;
  errorFriendDetails: string | undefined;
  counts: number | null;
  pages: number;
  loadingGetUserFriendsList: boolean;
  errorGetFriendsList: string | undefined;
  loadingGetFriendFriendsList: boolean;
  errorGetFriendFriendsList: string | undefined;
  loadingSendFriendRequest: boolean;
  errorSendFriendRequest: string | undefined;
  loadingCancelFriendRequest: boolean;
  errorCancelFriendRequest: string | undefined;
  loadingAcceptFriendRequest: boolean;
  errorAcceptFriendRequest: string | undefined;
  loadingRejectFriendRequest: boolean;
  errorRejectFriendRequest: string | undefined;
  loadingRemoveFriend: boolean;
  errorRemoveFriend: string | undefined;
  loadingBlockUser: boolean;
  errorBlockUser: string | undefined;
  loadingUnBlockUser: boolean;
  errorUnBlockUser: string | undefined;
  searchUsersLoading: boolean;
  searchUsersError: string | undefined;
  searchUsersList: SearchUser[]
  friendActivity: UserActivityType[];
  loadingFriendActivity: boolean;
  errorFriendActivity: string | undefined;
}

export const initialState: UserFriendsType = {
  searchedUsers: [],
  friendsList: [],
  friendFriendsList: [],
  friendsRequestsList: [],
  blockedUsersList: [],
  friendDetails: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    avatarUrl: "",
    isFriend: false,
    friendRequestId: "",
    isFriendRequestSendToMe: false,
    friendRequestToMeId: "",
    isFriendRequestSend: false,
    isBlocked: false,
    mutualFriends: 0,
    region: undefined,
    municipality: undefined,
    town: undefined,
    school: undefined,
    ranking: undefined,
  },
  loadingFriendDetails: false,
  errorFriendDetails: undefined,
  counts: null,
  pages: 0,
  loadingGetUserFriendsList: false,
  errorGetFriendsList: undefined,
  loadingGetFriendFriendsList: false,
  errorGetFriendFriendsList: undefined,
  loadingSendFriendRequest: false,
  errorSendFriendRequest: undefined,
  loadingCancelFriendRequest: false,
  errorCancelFriendRequest: undefined,
  loadingAcceptFriendRequest: false,
  errorAcceptFriendRequest: undefined,
  loadingRejectFriendRequest: false,
  errorRejectFriendRequest: undefined,
  loadingRemoveFriend: false,
  errorRemoveFriend: undefined,
  loadingBlockUser: false,
  errorBlockUser: undefined,
  loadingUnBlockUser: false,
  errorUnBlockUser: undefined,
  searchUsersLoading: false,
  searchUsersError: undefined,
  searchUsersList: [],
  friendActivity: [],
  loadingFriendActivity: false,
  errorFriendActivity: undefined
}


export const searchForFriends = createAsyncThunk('friendsSlice/searchForFriends', async ({search, region, municipality, town, school}: SearchFriendType) => {
  let response;
  try {
    response = await searchForFriendsService({search, region, municipality, town, school})
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const sendFriendshipRequest = createAsyncThunk('friendsSlice/sendFriendshipRequest', async ({ toUserId }: SendFriendsRequest) => {
  let response;
  try {
    response = await sendFriendshipRequestService({toUserId})
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})


export const cancelFriendshipRequest = createAsyncThunk('friendsSlice/cancelFriendshipRequest', async (requestId: string) => {
  let response;
  try {
    response = await cancelFriendshipRequestService(requestId)
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const acceptFriendshipRequest = createAsyncThunk('friendsSlice/acceptFriendshipRequest', async (requestId: string) => {
  let response;
  try {
    response = await acceptFriendshipRequestService(requestId)
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const rejectFriendshipRequest = createAsyncThunk('friendsSlice/rejectFriendshipRequest', async (requestId: string) => {
  let response;
  try {
    response = await rejectFriendshipRequestService(requestId)
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const getFriendships = createAsyncThunk('friendsSlice/getFriendships', async () => {
  let response;
  try {
    response = await getFriendshipsService()
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const getBlockedUsers = createAsyncThunk('friendsSlice/getBlockedUsers', async () => {
  let response;
  try {
    response = await getBlockedUsersService()
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const getFriendshipsRequests = createAsyncThunk('friendsSlice/getFriendshipsRequests', async () => {
  let response;
  try {
    response = await getFriendshipsRequestsService()
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const removeFriend = createAsyncThunk('friendsSlice/removeFriend', async (friendId: string) => {
  let response;
  try {
    response = await removeFriendService(friendId)
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const blockUser = createAsyncThunk('friendsSlice/blockUser', async (blockedUserId: string) => {
  let response;
  try {
    response = await blockUserService(blockedUserId)
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const unblockUser = createAsyncThunk('friendsSlice/unblockUser', async (blockId: string) => {
  let response;
  try {
    response = await unblockUserService(blockId)
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const getFriendDetails = createAsyncThunk('friendsSlice/getFriendDetails', async (friendId: string) => {
  let response;
  try {
    response = await friendDetailsService(friendId)
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const getFriendFriends = createAsyncThunk('friendsSlice/getFriendFriends', async (friendId: string) => {
  let response;
  try {
    response = await friendFriendsService(friendId)
  } catch (error) {
    console.error(error)
    throw error;
  }
  return response?.data
})

export const fetchFriendActivity = createAsyncThunk('user/fetchFriendActivity', async (friendId: string) => {
  let response
  try {
    response = await getFriendActivityService(friendId)
  } catch (error) {
    console.error(error)
    throw error
  }
  return response?.data
})


export const friendsSlice = createSlice({
  name: "friendsSlice",
  initialState,
  reducers: {
    cleanSearchErrorError: (state: UserFriendsType) => {
      state.searchUsersError = undefined
    },
    cleanFriendsList: (state: UserFriendsType) => {
      state.friendsList = []
    },
    cleanSearchUsersList: (state: UserFriendsType) => {
      state.searchedUsers = []
    },
    cleanErrors: (state: UserFriendsType) => {
      state.errorSendFriendRequest = undefined 
      state.errorCancelFriendRequest = undefined
      state.errorAcceptFriendRequest = undefined
      state.errorRejectFriendRequest = undefined
      state.errorRemoveFriend = undefined
      state.errorBlockUser = undefined
      state.errorUnBlockUser = undefined 
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(searchForFriends.pending, (state: UserFriendsType) => {
        state.searchUsersError = undefined
        state.searchUsersLoading = true
      })
      .addCase(searchForFriends.fulfilled, (state: UserFriendsType, actions) => {
        state.searchUsersLoading = false
        const allUsers: FriendUserModel[] = []
        actions.payload.forEach((user: any) => {
          const userObject = {
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            region: user.region,
            municipality: user.municipality,
            town: user.town,
            school: user.school,
            avatarUrl: user.avatar_url,
            isFriend: user.is_friend,
            friendRequestId: user.friend_request_id,
            isFriendRequestSend: user.is_friend_request_send,
            isBlocked: user.is_blocked,
            isFriendRequestSendToMe: user.is_friend_request_send_to_me,
            friendRequestToMeId: user.friend_request_to_me_id,
            mutualFriends: user.mutual_friends,
          }
          allUsers.push(userObject)
        })
        state.searchedUsers = allUsers
      })
      .addCase(searchForFriends.rejected, (state: UserFriendsType, action) => {
        state.searchUsersLoading = false
        state.searchUsersError = action.error.message
      })
      .addCase(getFriendships.pending, (state: UserFriendsType) => {
        state.errorGetFriendsList = undefined
        state.loadingGetUserFriendsList = false
      })
      .addCase(getFriendships.fulfilled, (state: UserFriendsType, actions) => {
        state.loadingGetUserFriendsList = false
        const myFriends: FriendUserModel[] = []
        actions.payload.forEach((user: any) => {
          const userObject = {
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            avatarUrl: user.avatar_url,
            isFriend: user.is_friend,
            friendRequestId: user.friend_request_id,
            isFriendRequestSend: user.is_friend_request_send,
            isBlocked: user.is_blocked,
            isFriendRequestSendToMe: user.is_friend_request_send_to_me,
            friendRequestToMeId: user.friend_request_to_me_id,
            mutualFriends: user.mutual_friends,
          }
          myFriends.push(userObject)
        })
        state.friendsList = myFriends
      })
      .addCase(getFriendships.rejected, (state: UserFriendsType, action) => {
        state.loadingGetUserFriendsList = false
        state.errorGetFriendsList = action.error.message
      })
      .addCase(getFriendFriends.pending, (state: UserFriendsType) => {
        state.errorGetFriendFriendsList = undefined
        state.loadingGetFriendFriendsList = false
      })
      .addCase(getFriendFriends.fulfilled, (state: UserFriendsType, actions) => {
        state.loadingGetFriendFriendsList = false
        const myFriends: FriendUserModel[] = []
        actions.payload.forEach((user: any) => {
          const userObject = {
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            school: user.school,
            town: user.town,
            avatarUrl: user.avatar_url,
            isFriend: user.is_friend,
            friendRequestId: user.friend_request_id,
            isFriendRequestSend: user.is_friend_request_send,
            isBlocked: user.is_blocked,
            isFriendRequestSendToMe: user.is_friend_request_send_to_me,
            friendRequestToMeId: user.friend_request_to_me_id,
            mutualFriends: user.mutual_friends,
          }
          myFriends.push(userObject)
        })
        state.friendFriendsList = myFriends
      })
      .addCase(getFriendFriends.rejected, (state: UserFriendsType, action) => {
        state.loadingGetFriendFriendsList = false
        state.errorGetFriendFriendsList = action.error.message
      })
      .addCase(getFriendshipsRequests.pending, (state: UserFriendsType) => {
        state.errorGetFriendsList = undefined
        state.loadingGetUserFriendsList = true
      })
      .addCase(getFriendshipsRequests.fulfilled, (state: UserFriendsType, actions) => {
        state.loadingGetUserFriendsList = false
        const myFriends: FriendUserModel[] = []
        actions.payload.forEach((user: any) => {
          const userObject = {
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            avatarUrl: user.avatar_url,
            isFriend: user.is_friend,
            friendRequestId: user.friend_request_id,
            isFriendRequestSend: user.is_friend_request_send,
            isBlocked: user.is_blocked,
            isFriendRequestSendToMe: user.is_friend_request_send_to_me,
            friendRequestToMeId: user.friend_request_to_me_id,
            mutualFriends: user.mutual_friends,
          }
          myFriends.push(userObject)
        })
        state.friendsRequestsList = myFriends
      })
      .addCase(getFriendshipsRequests.rejected, (state: UserFriendsType, action) => {
        state.loadingGetUserFriendsList = false
        state.errorGetFriendsList = action.error.message
      })
      .addCase(getBlockedUsers.pending, (state: UserFriendsType) => {
        state.errorGetFriendsList = undefined
        state.loadingGetUserFriendsList = true
      })
      .addCase(getBlockedUsers.fulfilled, (state: UserFriendsType, actions) => {
        state.loadingGetUserFriendsList = false
        const myFriends: FriendUserModel[] = []
        actions.payload.forEach((user: any) => {
          const userObject = {
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            avatarUrl: user.avatar_url,
            isFriend: user.is_friend,
            friendRequestId: user.friend_request_id,
            isFriendRequestSend: user.is_friend_request_send,
            isBlocked: user.is_blocked,
            isFriendRequestSendToMe: user.is_friend_request_send_to_me,
            friendRequestToMeId: user.friend_request_to_me_id,
            mutualFriends: user.mutual_friends,
          }
          myFriends.push(userObject)
        })
        state.blockedUsersList = myFriends
      })
      .addCase(getBlockedUsers.rejected, (state: UserFriendsType, action) => {
        state.loadingGetUserFriendsList = false
        state.errorGetFriendsList = action.error.message
      })
      .addCase(sendFriendshipRequest.pending, (state: UserFriendsType) => {
        state.errorSendFriendRequest = undefined
        state.loadingSendFriendRequest = true
      })
      .addCase(sendFriendshipRequest.fulfilled, (state: UserFriendsType) => {
        state.loadingSendFriendRequest = false        
      })
      .addCase(sendFriendshipRequest.rejected, (state: UserFriendsType, action) => {
        state.loadingSendFriendRequest = false
        state.errorSendFriendRequest = action.error.message
      })
      .addCase(cancelFriendshipRequest.pending, (state: UserFriendsType) => {
        state.errorCancelFriendRequest = undefined
        state.loadingCancelFriendRequest = true
      })
      .addCase(cancelFriendshipRequest.fulfilled, (state: UserFriendsType) => {
        state.loadingCancelFriendRequest = false        
      })
      .addCase(cancelFriendshipRequest.rejected, (state: UserFriendsType, action) => {
        state.loadingCancelFriendRequest = false
        state.errorCancelFriendRequest = action.error.message
      })
      .addCase(acceptFriendshipRequest.pending, (state: UserFriendsType) => {
        state.errorAcceptFriendRequest = undefined
        state.loadingAcceptFriendRequest = true
      })
      .addCase(acceptFriendshipRequest.fulfilled, (state: UserFriendsType) => {
        state.loadingAcceptFriendRequest = false        
      })
      .addCase(acceptFriendshipRequest.rejected, (state: UserFriendsType, action) => {
        state.loadingAcceptFriendRequest = false
        state.errorAcceptFriendRequest = action.error.message
      })
      .addCase(rejectFriendshipRequest.pending, (state: UserFriendsType) => {
        state.errorRejectFriendRequest = undefined
        state.loadingRejectFriendRequest = true
      })
      .addCase(rejectFriendshipRequest.fulfilled, (state: UserFriendsType) => {
        state.loadingRejectFriendRequest = false        
      })
      .addCase(rejectFriendshipRequest.rejected, (state: UserFriendsType, action) => {
        state.loadingRejectFriendRequest = false
        state.errorRejectFriendRequest = action.error.message
      })
      .addCase(removeFriend.pending, (state: UserFriendsType) => {
        state.errorRemoveFriend = undefined
        state.loadingRemoveFriend = true
      })
      .addCase(removeFriend.fulfilled, (state: UserFriendsType) => {
        state.loadingRemoveFriend = false        
      })
      .addCase(removeFriend.rejected, (state: UserFriendsType, action) => {
        state.loadingRemoveFriend = false
        state.errorRemoveFriend = action.error.message
      })
      .addCase(blockUser.pending, (state: UserFriendsType) => {
        state.errorBlockUser = undefined
        state.loadingBlockUser = true
      })
      .addCase(blockUser.fulfilled, (state: UserFriendsType) => {
        state.loadingBlockUser = false        
      })
      .addCase(blockUser.rejected, (state: UserFriendsType, action) => {
        state.loadingBlockUser = false
        state.errorBlockUser = action.error.message
      })
      .addCase(unblockUser.pending, (state: UserFriendsType) => {
        state.errorUnBlockUser = undefined
        state.loadingUnBlockUser = true
      })
      .addCase(unblockUser.fulfilled, (state: UserFriendsType) => {
        state.loadingUnBlockUser = false        
      })
      .addCase(unblockUser.rejected, (state: UserFriendsType, action) => {
        state.loadingUnBlockUser = false
        state.errorUnBlockUser = action.error.message
      })
      .addCase(getFriendDetails.pending, (state: UserFriendsType) => {
        state.errorFriendDetails = undefined
        state.loadingFriendDetails = true
      })
      .addCase(getFriendDetails.fulfilled, (state: UserFriendsType, actions) => {
        state.loadingFriendDetails = false
        state.friendDetails.avatarUrl = actions.payload.avatar_url
        state.friendDetails.firstName = actions.payload.first_name
        state.friendDetails.lastName = actions.payload.last_name
        state.friendDetails.email = actions.payload.email,
        state.friendDetails.mutualFriends = actions.payload.mutual_friends,
        state.friendDetails.region = actions.payload.region,
        state.friendDetails.municipality = actions.payload.municipality,
        state.friendDetails.town = actions.payload.town,
        state.friendDetails.school = actions.payload.school,
        state.friendDetails.ranking = actions.payload.ranking
      })
      .addCase(getFriendDetails.rejected, (state: UserFriendsType, action) => {
        state.errorFriendDetails = action.error.message
        state.loadingFriendDetails = false
      })
      builder
      .addCase(fetchFriendActivity.pending, (state: UserFriendsType) => {
        state.loadingFriendActivity = true
      })
      .addCase(fetchFriendActivity.fulfilled, (state: UserFriendsType, action) => {
        state.loadingFriendActivity = false
        const userActivityList: UserActivityType[] = []
        action.payload.forEach((activity: UserActivityBackendType) => {
          const userActivityObject: UserActivityType = {
            lastUpdate: activity.create_at,
            cardRequests: activity.card_requests,
            learnedCardsVerbal: activity.learned_cards_verbal,
            learnedCardsTyping: activity.learned_cards_typing,
            completedCategoriesVerbal: activity.completed_categories_verbal,
            completedCategoriesTyping: activity.completed_categories_typing,
            dailyLimit: activity.daily_limit
          }
          userActivityList.push(userActivityObject)
        })

        state.friendActivity = userActivityList
      })
      .addCase(fetchFriendActivity.rejected, (state: UserFriendsType, action) => {
        state.loadingFriendActivity = false
        state.errorFriendActivity = action.error.message
      })
  }
})

export const actions = friendsSlice.actions;

export const friendsSliceSelector = (state: RootState) => state[friendsSlice.name];
import { BASE_URL } from "../common/constants";
import axios from "axios";
import { withTokenCheck } from './userAxiosServices'
import { SearchFriendType, SendFriendsRequest } from "../common/friends-model";


const getFriendshipsRequests = async () => {
  const response = axios.get(BASE_URL + `friends/friendship-requests/`, {
    
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getFriendshipsRequestsService = withTokenCheck(getFriendshipsRequests)


const sendFriendshipRequest = async ({ toUserId }: SendFriendsRequest) => {
    const body = { 
      to_user: toUserId,
    }
    const response = axios.post(BASE_URL + `friends/friendship-requests/`, body, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('access')}`,
        }
      });
    return response;
  }
  
  export const sendFriendshipRequestService = withTokenCheck(sendFriendshipRequest)


const acceptFriendshipRequest = async (requestId: string) => {
  const body = { 
    request_id: requestId,
  }
    const response = axios.put(BASE_URL + `friends/friendship-requests/${requestId}/accept/`, body, {
      
      headers: {
        Authorization: `JWT ${localStorage.getItem('access')}`,
        }
      });
    return response;
  }
  
  export const acceptFriendshipRequestService = withTokenCheck(acceptFriendshipRequest)


const rejectFriendshipRequest = async (requestId: string) => {
  const body = { 
    request_id: requestId,
  }
  const response = axios.put(BASE_URL + `friends/friendship-requests/${requestId}/reject/`, body, {
    
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const rejectFriendshipRequestService = withTokenCheck(rejectFriendshipRequest)


const cancelFriendshipRequest = async (requestId: string) => {
  const response = axios.delete(BASE_URL + `friends/friendship-requests/${requestId}/cancel/`, {
    
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const cancelFriendshipRequestService = withTokenCheck(cancelFriendshipRequest)


// Get a List of Friendships
const getFriendships = async () => {
  const response = axios.get(BASE_URL + `friends/friendships/`, {
    
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getFriendshipsService = withTokenCheck(getFriendships)


// Remove a Friend
const removeFriend = async (friendId: string) => {
  const response = axios.delete(BASE_URL + `friends/friendships/${friendId}/remove/`, {
    
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const removeFriendService = withTokenCheck(removeFriend)


// Block a User
const blockUser = async (blockedUserId: string) => {
  const body = {
    blocker: blockedUserId,
    blocked: blockedUserId,
  };
  const response = axios.post(BASE_URL + `friends/blocks/`, body, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const blockUserService = withTokenCheck(blockUser)


// Unblock a User
const unblockUser = async (blockId: string) => {
  const response = axios.delete(BASE_URL + `friends/blocks/${blockId}/unblock/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const unblockUserService = withTokenCheck(unblockUser)


// Get a List of Blocked Users
const getBlockedUsers = async () => {
  const response = axios.get(BASE_URL + `friends/blocks/list/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const getBlockedUsersService = withTokenCheck(getBlockedUsers)

const searchForFriends = ({search, region, municipality, town, school}: SearchFriendType) => {
  const response = axios.get(BASE_URL + 'friends/search-friends/', {
    params: {
      search: search,
      region: region,
      municipality: municipality,
      town: town,
      school: school
    },
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const searchForFriendsService = withTokenCheck(searchForFriends)


const friendDetails = async (friendId: string) => {
  const response = axios.get(BASE_URL + `friends/friend-details/${friendId}/`, {
    
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const friendDetailsService = withTokenCheck(friendDetails)

const friendFriends = async (friendId: string) => {
  const response = axios.get(BASE_URL + `friends/friend-details/${friendId}/friends/`, {
    
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
  return response;
}

export const friendFriendsService = withTokenCheck(friendFriends)
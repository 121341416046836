import { EditUserServiceType, PasswordConfirmType, User, activateUserAccountServiceType } from "../common/user-model";
import { BASE_URL } from "../common/constants";
import axios, { AxiosResponse } from "axios";

// const checkUserAccessTokenService = async (token: string) => {
//   const response = axios.post(BASE_URL + 'user/jwt/verify/', {
//     token
//   });
//   return response
// }

const checkUserRefreshTokenService = async (refresh: string) => {
  const response = axios.post(BASE_URL + 'user/jwt/refresh/', {
    refresh
  });
  return response
}

type RequestFunction = (...args: any[]) => Promise<AxiosResponse>;

export const withTokenCheck = (requestFunction: RequestFunction) => async (...args: Parameters<RequestFunction>) => {
    const access = localStorage.getItem('access');
    if (access) {
      const decodedToken = JSON.parse(atob(access.split('.')[1]));
      const expirationTime = decodedToken.exp * 1000; 
        if (Date.now() < expirationTime) {
        return await requestFunction(...args);
      }
    }
    const refresh = localStorage.getItem('refresh');
    if(refresh) {
      try {
        const refreshResponse = await checkUserRefreshTokenService(refresh);
        const newAccess = refreshResponse.data.access;
        const newRefresh = refreshResponse.data.refresh;
        localStorage.setItem('access', newAccess);
        localStorage.setItem('refresh', newRefresh);
        return await requestFunction(...args);
      } catch(error) {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
      }
    } else {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
    }
};

export const registerUserService = async ({
  firstName,
  lastName,
  email,
  password,
  rePassword
}: User) => {
  const response = axios.post(BASE_URL + 'user/users/', {
    first_name: firstName,
    last_name: lastName,
    email: email,
    password: password,
    re_password: rePassword
  })
  return response
};

export const loginUserService = async ({ email, password }: Pick<User, 'email' | 'password'>) => {
  const response = axios.post(BASE_URL + 'user/jwt/create/', {
    email: email,
    password: password
  })
  return response;
}

export const activateUserAccountService = async ({ uid, token }: activateUserAccountServiceType) => {
  axios.post(BASE_URL + 'user/users/activation/', {
    uid: uid,
    token: token
  })
}

export const loadUserDetails = async () => {
  const response = axios.get(BASE_URL + 'user/users/me/', {
    headers: {
      'Authorization': `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}
export const loadUserDetailsService = withTokenCheck(loadUserDetails)

export const resetPasswordService = async (email: string) => {
  const response = axios.post(BASE_URL + 'user/users/reset_password/', {
    email
  });
  return response;
}

export const resetPasswordConfirmService = async ({ uid, token, newPassword, reNewPassword }: PasswordConfirmType) => {
  const response = axios.post(BASE_URL + 'user/users/reset_password_confirm/', {
    uid: uid,
    token: token,
    new_password: newPassword,
    re_new_password: reNewPassword
  });

  return response;
}

export const uploadProfilePicture = async (file: File) => {
  if (!file) {
    return Promise.reject(new Error("No file provided"));
  }

  const formData = new FormData();
  formData.set("avatar", file);

  const response = await axios.post(BASE_URL + "upload-avatar/", formData, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("access")}`,
      "content-Type": "multipart/form-data",
    },
  });

  return response;
}

export const uploadProfilePictureService = withTokenCheck(uploadProfilePicture)


const editUserDetails = async ({
  id,
  avatar,
  firstName,
  lastName,
  email,
  region,
  municipality,
  town,
  school,
  gender,
  dateOfBirth,
  receiveInvitationsForCategories,
  receiveInvitationsForContest,
  receiveInvitationsForFriends,
  receivePromoEmails
}: EditUserServiceType) => {
  try {
    const formData = new FormData();

    // Append all defined fields to FormData
    if (id) formData.append('id', id);
    if (avatar) formData.append('avatar', avatar);
    if (firstName) formData.append('first_name', firstName);
    if (lastName) formData.append('last_name', lastName);
    if (dateOfBirth) formData.append('date_of_birth', dateOfBirth);
    if (email) formData.append('email', email);
    if (region) formData.append('region', region);
    if (municipality) formData.append('municipality', municipality);
    if (town) formData.append('town', town);
    if (school) formData.append('school', school);
    if (gender) formData.append('gender', gender);
    if (receiveInvitationsForCategories !== undefined) {
      formData.append(
        'receive_invitations_for_categories',
        String(receiveInvitationsForCategories)
      );
    }
    if (receiveInvitationsForContest !== undefined) {
      formData.append(
        'receive_invitations_for_contest',
        String(receiveInvitationsForContest)
      );
    }
    if (receiveInvitationsForFriends !== undefined) {
      formData.append(
        'receive_invitations_for_friends',
        String(receiveInvitationsForFriends)
      );
    }
    if (receivePromoEmails !== undefined) {
      formData.append(
        'receive_promo_emails',
        String(receivePromoEmails)
      );
    }

    // Make the PUT request
    const response = await axios.put(BASE_URL + 'user/users/me/', formData, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('access')}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error: any) {
    console.error('Failed to edit user details:', error);
    throw error.response?.data || error.message;
  }
};
export const editUserDetailsService = withTokenCheck(editUserDetails)
import { BASE_URL } from "../common/constants";
import axios, { AxiosResponse } from "axios";
import { withTokenCheck } from './userAxiosServices'
import { aiGenerateCardText, aiGenerateCardWithImages } from "../common/card-model";

const POLL_INTERVAL = 2000; // Poll every 2 seconds

const pollTaskStatus = async (taskId: string): Promise<any> => {
  let isTaskComplete = false;
  let taskResult = null;

  while (!isTaskComplete) {
    try {
      const response = await axios.get(BASE_URL + `card/generate-flashcards-from-images/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('access')}`,
        },
        params: { task_id: taskId },
      });

      if (response.data.status === "SUCCESS") {
        isTaskComplete = true;
        taskResult = response; // The flashcards result
      } else if (response.data.status === "FAILURE") {
        throw new Error(response.data.error || "Task failed");
      }
    } catch (error) {
      console.error("Error while polling task status:", error);
      throw error;
    }

    if (!isTaskComplete) {
      await new Promise((resolve) => setTimeout(resolve, POLL_INTERVAL));
    }
  }
  return taskResult;
};
const pollTaskCardTextStatus = async (taskId: string): Promise<any> => {
  let isTaskComplete = false;
  let taskResult = null;

  while (!isTaskComplete) {
    try {
      const response = await axios.get(BASE_URL + `card/generate-flashcards/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('access')}`,
        },
        params: { task_id: taskId },
      });

      if (response.data.status === "SUCCESS") {
        isTaskComplete = true;
        taskResult = response; // The flashcards result
      } else if (response.data.status === "FAILURE") {
        throw new Error(response.data.error || "Task failed");
      }
    } catch (error) {
      console.error("Error while polling task status:", error);
      throw error;
    }

    if (!isTaskComplete) {
      await new Promise((resolve) => setTimeout(resolve, POLL_INTERVAL));
    }
  }
  return taskResult;
};

const generateCardsText = async ({ topic, sideOne, sideTwo, tag, level, audienceAge, language, cardNumber }: aiGenerateCardText) => {
  let response
  const body = {
    topic: topic,
    sideOne: sideOne,
    sideTwo: sideTwo,
    tag: tag,
    level: level,
    audienceAge: audienceAge,
    language: language,
    cardNumber: cardNumber
  }
  try {
    const taskResponse = await axios.post(BASE_URL + `card/generate-flashcards/`, body, {
  
      headers: {
        Authorization: `JWT ${localStorage.getItem('access')}`,
      }
    });
    const taskId = taskResponse.data.task_id;
    response = await pollTaskCardTextStatus(taskId);
  } catch (error) {
    console.log(error)
    throw error
  }
    if (!response) {
      // If response is still undefined, create a placeholder response with required properties
      response = {
        data: undefined,
        status: 500, // You may want to adjust the status code based on your needs
        statusText: 'Internal Server Error',
        headers: {},
        config: {},
      };
    }
    console.log(response);
    return response as AxiosResponse<any, any>;
}

export const generateCardsTextService = withTokenCheck(generateCardsText)




const generateCardsWithImages = async ({ firstImage, secondImage, thirdImage, cardQuantity, language, tag }: aiGenerateCardWithImages) => {
  let response
  if (firstImage && language) {
    const formData = new FormData();
    formData.append('first_image', firstImage);
    formData.append('card_quantity', cardQuantity);
    formData.append('language', language)
    formData.append('tag', tag)
    if(secondImage) {
      formData.append('second_image', secondImage);
    }
    if(thirdImage) {
      formData.append('third_image', thirdImage);
    }
    try {
      const taskResponse = await axios.post(BASE_URL + 'card/generate-flashcards-from-images/', formData, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('access')}`,
          'content-Type': 'multipart/form-data'
        }
      });
      const taskId = taskResponse.data.task_id;
      response = await pollTaskStatus(taskId); 
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  if (!response) {
    // If response is still undefined, create a placeholder response with required properties
    response = {
      data: undefined,
      status: 500, // You may want to adjust the status code based on your needs
      statusText: 'Internal Server Error',
      headers: {},
      config: {},
    };
  }
  //console.log(response);
  return response as AxiosResponse<any, any>;
}

export const generateCardsWithImagesService = withTokenCheck(generateCardsWithImages)

export interface aiCreateCardTextProps {
  categoryName: string;
  flashcards: {
    sideOne: string;
    sideTwo: string;
    tag: string;
  }[];
}

const aiCreateCardText = async ({ categoryName, flashcards }: aiCreateCardTextProps) => {
  const body = {
    category_name: categoryName,
    flashcards: flashcards
  }
  const response = await axios.post(BASE_URL + `card/create-ai-flashcards/`, body, {

    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const aiCreateCardTextService = withTokenCheck(aiCreateCardText)